<template>
    <div class="grid">
        <div class="col-2 text-center">
            <h4>Ordenes por cliente</h4>
            <Chart type="pie" :data="clientData" :options="orderXCliente" />
        </div>
        <!-- <div class="col-4 text-center">
            <h4>Horas de operarios</h4>
            <Chart type="bar" :data="operatorsData" :options="lightOptions" />
        </div>
        <div class="col-4 text-center">
            <h4>Facturación por cliente</h4>
            <Chart type="bar" :data="clientData2" :options="clientData2Options" />
        </div> -->
        
    </div>
</template>

<script>
    import axios from 'axios'
export default {
    data() {
        return {
            clientData: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#42A5F5","#66BB6A","#FFA726"],
                        hoverBackgroundColor: ["#64B5F6","#81C784","#FFB74D"]
                    }
                ]
            },
            clientData2: {
                labels: ['Cliente 1','Cliente 2','Cliente 3', 'Cliente 4', 'Cliente 5', 'Cliente 6', 'Cliente 7', 'Cliente 8', 'Cliente 9', 'Cliente 10'],
                datasets: [
                    {
                        data: [50,25,12,48,90,76,42,25,12,48],
                        backgroundColor: ["#66BB6A"],
                        hoverBackgroundColor: ["#64B5F6"]
                    }
                ]
            },
            operatorsData: {
                labels: ['Operario 1', 'Operario 2', 'Operario 3', 'Operario 4', 'Operario 5', 'Operario 6', 'Operario 7'],
                datasets: [{
                    type: 'bar',
                    label: 'Produciendo',
                    backgroundColor: '#66BB6A',
                    data: [50,25,12,48,90,76,42]
                }, {
                    type: 'bar',
                    label: 'Pausado',
                    backgroundColor: '#FFA726',
                    data: [21,84,24,75,37,65,34]
                }]
            },
            lightOptions: {
                scales: {
                y: {
                    ticks: {
                        // Include a dollar sign in the ticks
                        callback: function(value, index, ticks) {
                            return  value + " Hs.";
                        }
                    }
                }
            },
            plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                }
            },
            orderXCliente: {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                }
            },
            clientData2Options: {
                scales: {
            y: {
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function(value, index, ticks) {
                        return  "$ " + value;
                    }
                }
            }
        },
                plugins: {
                    legend: {
        display: false
    },
    tooltips: {
        callbacks: {
           label: function(tooltipItem) {
                  return tooltipItem.yLabel;
           }
        }
    }
                }
            }
        }
    },
    mounted() {
        axios.get('https://ferraroback.osolehosting.com/stats/clients-ocv')
            .then(response => {
                
                let labels = []
                let data = []
                response.data.forEach(element => {
                    labels.push(element.name)
                    data.push(element.total)
                });

                this.clientData =  {
                    labels: labels,
                    datasets: [
                        {
                            data: data,
                            backgroundColor: ["#42A5F5","#66BB6A","#FFA726"],
                            hoverBackgroundColor: ["#64B5F6","#81C784","#FFB74D"]
                        }
                    ]
                }
            })
    },
    created() {
        
    }
}
</script>