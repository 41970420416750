import { createRouter, createWebHashHistory } from 'vue-router';
import Dashboard from './components/Dashboard.vue';

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
    },
    {
        path: '/cliente',
        name: 'cliente',
        component: () => import('./pages/Clientes.vue')
    },
    {
        path: '/facrec/crear',
        name: 'CreateFacRec',
        component: () => import('./pages/facrec/create.vue')
    },
    {
        path: '/bancos',
        name: 'bancos',
        component: () => import('./pages/Bancos.vue')
    },
    { 
        path: '/cheques',
        name: 'cheques',
        component: () => import('./pages/Cheques.vue')
    },
    {
        path: '/cc',
        name: 'cc',
        component: () => import('./pages/cc/ccprovs.vue')
    },
    {
        path: '/proveedor',
        name: 'proveedor',
        component: () => import('./pages/Proveedores.vue')
    },
    {
        path: '/2a/proveedores',
        name: '2aproveedores',
        component: () => import('./pages/2a/prov/index.vue')
    },
    {
        path: '/maquinaria',
        name: 'maquinaria',
        component: () => import('./pages/Maquinaria.vue')
    },
    {
        path: '/logistica',
        name: 'logistica',
        component: () => import('./pages/Logistica.vue')
    },
    {
        path: '/material',
        name: 'material',
        component: () => import('./pages/Materiales.vue')
    },
    {
        path: '/instrumentos',
        name: 'instrumentos',
        component: () => import('./pages/Instrumentos.vue')
    },
    {
        path: '/planos',
        name: 'planos',
        component: () => import('./pages/Planos.vue')
    },
    {
        path: '/piezas',
        name: 'piezas',
        component: () => import('./pages/Piezas.vue')
    },
    {
        path: '/piezas/edit/:id',
        name: 'edit-piece',
        component: () => import('./pages/piezas/edit.vue')
    },
    {
        path: '/piezas/:id/ver',
        name: 'show-piece',
        component: () => import('./pages/piezas/show.vue')
    },
    {
        path: '/piezas/:id/tiempos',
        name: 'show-piece-times',
        component: () => import('./pages/piezas/showtime.vue')
    },
    {
        path: '/piezas/:id/calidad',
        name: 'show-piece-calidad',
        component: () => import('./pages/piezas/showcalidad.vue')
    },
    {
        path: '/usuario',
        name: 'usuario',
        component: () => import('./pages/Users.vue')
    },
    {
        path: '/cotizaciones',
        name: 'cotizaciones',
        component: () => import('./pages/cotizaciones/index.vue')
    },
    {
        path: '/cotizaciones/crear',
        name: 'CreateBudget',
        component: () => import('./pages/cotizaciones/create.vue')
    },
    {
        path: '/cotizaciones/:id',
        name: 'EditBudget',
        component: () => import('./pages/cotizaciones/edit.vue')
    },
    {
        path: '/ventas',
        name: 'ventas',
        component: () => import('./pages/ventas/index.vue')
    },
    {
        path: '/entregas',
        name: 'entregas',
        component: () => import('./pages/dispatch/index.vue')
    },
    {
        path: '/facturacion',
        name: 'facturacion',
        component: () => import('./pages/facturar/index.vue')
    },
    {
        path: '/contabilidad',
        name: 'contabilidad',
        component: () => import('./pages/contabilidad/index.vue')
    },
    {
        path: '/contabilidad/recibos',
        name: 'recibos',
        component: () => import('./pages/contabilidad/recibos.vue')
    },
    {
        path: '/facturar/:id',
        name: 'facturar',
        component: () => import('./pages/facturar/create.vue')
    },
    {
        path: '/dispatch/:id',
        name: 'dispatch',
        component: () => import('./pages/dispatch/create.vue')
    },
    {
        path: '/dispatch-new',
        name: 'dispatch-new',
        component: () => import('./pages/dispatch/create.vue')
    },
    {
        path: '/compras',
        name: 'compras',
        component: () => import('./pages/compras/index.vue')
    },
    {
        path: '/ventas/crear',
        name: 'CreateSell',
        component: () => import('./pages/ventas/create.vue')
    },
    {
        path: '/compras/crear',
        name: 'CreateBuy',
        component: () => import('./pages/compras/create.vue')
    },
    {
        path: '/2A/compras/crear',
        name: 'CreateBuyAA',
        component: () => import('./pages/2a/compras/create.vue')
    },
    {
        path: '/2a/inicio',
        name: '2ahome',
        component: () => import('./pages/2a/ingenieria/Maestro.vue')
    },
    {
        path: '/2A/ingenieria',
        name: '2AIngenieria',
        component: () => import('./pages/2a/ingenieria/Maestro.vue')
    },
    {
        path: '/remitos',
        name: 'remitos',
        component: () => import('./pages/remitos/index.vue')
    },
    {
        path: '/remitos/crear',
        name: 'CreateRemito',
        component: () => import('./pages/remitos/create.vue')
    },
    {
        path: '/produccion',
        name: 'produccion',
        component: () => import('./pages/production/list.vue')
    },
    {
        path: '/produccion/:id',
        name: 'ordenprod',
        component: () => import('./pages/production/show.vue')
    },
    {
        path: '/orden/:id',
        name: 'oti',
        component: () => import('./pages/production/oti_show.vue')
    },
    {
        path: '/orden_config/:id',
        name: 'oticonfig',
        component: () => import('./pages/production/oti_config.vue')
    },
    {
        path: '/produccion/:id/addorder',
        name: 'CreateOti',
        component: () => import('./pages/production/oti_create.vue')
    },
    {
        path: '/produccion/crear',
        name: 'CreateProduccion',
        component: () => import('./pages/production/create.vue')
    },
    {
        path: '/piezas/crear',
        name: 'CreatePieza',
        component: () => import('./pages/piezas/create.vue')
    },
    {
        path: '/estadisticas/:of',
        name: 'Stats',
        component: () => import('./pages/Stats.vue')
    },
    {
        path: '/mantenimiento',
        name: 'Mantenimiento',
        component: () => import('./pages/Mantenimiento.vue')
    },
    {
        path: '/costos',
        name: 'CostoMod',
        component: () => import('./pages/Costos.vue')
    },
    // 2A
    {
        path: '/2a/compras',
        name: '2acompras',
        component: () => import('./pages/2a/compras/index.vue')
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
