<template>
	<div>
  <div class="grid">

    <!-- <div class="col-12">

      <ResumedCharts />

    </div> -->
    <div class="col-12">
      <OrdersToDispatch />

    </div>
		<!-- <div class="col-3" >
			<div class="card" style="border-bottom: 5px solid #9A9A9A">
        
        <h1>{{ countStatus(0)}}</h1>

        <h3>SIN INICIAR</h3>
  
			</div>
		</div>
		<div class="col-3" >
			<div class="card" style="border-bottom: 5px solid #1C95C2">
        
        <h1>{{ countStatus(1)}}</h1>

        <h3>EN PROCESO</h3>
  
			</div>
		</div>
		<div class="col-3" >
			<div class="card" style="border-bottom: 5px solid #EEC216">
        
        <h1>{{ countStatus(2)}}</h1>

        <h3>EN PAUSA</h3>
  
			</div>
		</div> -->

    <!--#7E57C2 EN CALIDAD ? --->

		<!-- <div class="col-3" >
			<div class="card" style="border-bottom: 5px solid #66BB6A">
        
        <h1>{{ countStatus(3)}}</h1>

        <h3>FINALIZADAS</h3>
  
			</div>
		</div> -->


  </div> 
  <h3>Producción</h3>
  <TabView ref="tabview1">

    <TabPanel header="TODAS"  >
                    <div class="grid"> <OtiSquare v-for="order in orders" :key="order.id" :userGroup="opGroup" :order="order" /> </div>
                </TabPanel> 
                
    <TabPanel header="SIN CONFIGURAR"  >
                    <div class="grid"> <OtiSquare v-for="order in orders.filter((order) =>{ return order.db_status === '0'  || order.status == 0})" :key="order.id" :userGroup="opGroup" :order="order" /> </div>
                </TabPanel> 
              <!-- <TabPanel header="EN PROCESO">
                    <div class="grid"> <OtiSquare v-for="order in orders.filter((order) =>{ return order.status === '1'  || order.status == 1})" :key="order.id" :userGroup="opGroup" :order="order" /> </div>
                </TabPanel>
          <TabPanel header="ASIGNADAS">
                    <div class="grid"> <OtiSquare v-for="order in orders.filter((order) => {return order.status === '4' || order.status == 4})" :key="order.id" :userGroup="opGroup" :order="order" /> </div>
                </TabPanel>
                <TabPanel header="SIN ASIGNAR / SIN INICIAR">
                    <div class="grid"> 
                      <OtiSquare v-for="order in orders.filter((order) => {return order.status === '0' || order.status == 0})"
                        :key="order.id" :userGroup="opGroup" :order="order" 
                        :operators="operators" 
                       /> 
                    </div>
                </TabPanel> 
                <TabPanel header="EN PAUSA">
                    <div class="grid"> <OtiSquare v-for="order in orders.filter((order) => { return order.status === '5' || order.status === '2'})" :key="order.id" :userGroup="opGroup" :order="order" /> </div>
                </TabPanel>
                <TabPanel header="EN CALIDAD">
                    <div class="grid"> <OtiSquare v-for="order in orders.filter((order) => order.status === '3')" :key="order.id" :userGroup="opGroup" :order="order" /> </div>
                </TabPanel>
                <TabPanel header="FINALIZADAS">
                    <div class="grid"> <OtiSquare v-for="order in orders.filter((order) => order.status === '4')" :key="order.id" :userGroup="this.opGroup" :order="order" /> </div>
                </TabPanel> -->
                
  </TabView>


  </div>

</template>

<script>
import CrudService from "./../../services/crud.service.js" 
import OtiSquare from "./OtiSquare.vue";
import ResumedCharts from "./ResumedCharts.vue";
import OperatorService from "./../../services/operator.service.js";
import OrdersToDispatch from "./OrdersToDispatch.vue";

export default {
  props: ['opGroup'],
  data() {
    return {
      orders: [],
      operators: [],
    }
  },
  components: {
    OtiSquare,
    ResumedCharts,
    OrdersToDispatch,
  },
	computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    currentGroup() {
      return this.$store.state.auth;
    },
  },
  mounted() {
    if (!this.currentUser) {
      console.log('no current user in Adm Dashboar Vue')
      //this.$router.push('/login');
    }

    this.getOrders();

    this.getOperators();
  }, 
  methods: {
        getOperators() {
            OperatorService.getOperators().then(response => {
                this.operators = response;
            });
        },
    countStatus(status) {
      return this.orders.filter((order) => order.status === status).length;
    },
    getOrders() {

        CrudService.getCRUD("api/orden", "orden").then((data) => (this.orders = data));


    },

  }

}
</script>

<style type="text/scss"
scoped>
    .motiveButton {
        
        font-size: 1.8rem;
        text-transform: uppercase;
        margin: 1%;
        background: #ff99005b;
        color: black;
        border: 1px solid #ff99005b;
        &:hover {
            background: #ff9900;
            color: black;
            border: 1px solid #ff9900;
        }
    }
</style>