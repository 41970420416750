<template>
	<div class="layout-topbar">
		<router-link to="/" :class="isferraro ? ' topmenusel  ' : ' switchbutton '"  @click="this.$emit('menu-ferr', event);"
		v-if="user.roles && user.roles[0].id !== 7">
			
			<span>Metalúrgica Ferraro</span>
		</router-link> 

		<router-link to="/2a/inicio" :class="!isferraro ? 'topmenusel ' : '  switchbutton '"
		v-if="user && user.roles && user.roles[0].id == 7"
	 	 @click="this.$emit('menu-2a', event);">
		 <span>

			 2 A
		 </span>
		</router-link>
 
		<button v-if="user && user.groups && user.groups[0] !== 2" class="p-link layout-menu-button layout-topbar-button" @click="cleanProd()">
			 LIMPIAR PRODUCCIÖN !!!!
		</button>

		<button v-if="user && user.groups && user.groups[0] !== 2" class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>

		</button>
		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li>

				<button class="p-link layout-topbar-button-user">
					
					<i class="pi pi-user inverted-link"></i>					
					
					{{ user['name'] }} {{ user['last_name'] }}

				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-logout mt-1"  @click="logout()">
				Cerrar Sesión
				</button>
			</li>
		</ul>
	</div> 
</template>

<script>
export default {
	props: ["user", "isferraro"],
	data() {
		return { 

		}
	},
	mounted() {
	 	if(this.user && this.user.roles && this.user.roles[0].id == 2){
            this.onMenuToggle();

        }
	},
    methods: {
		logout(){

			// delete token from local storage
			// localStorage.removeItem(process.env.VUE_APP_SLUG + '_profile');
			localStorage.removeItem('api_token');

			// redirect to login page

			window.location.href = "/"

		},
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return this.$appState.darkTheme ? 'images/logo.jpeg' : 'images/logo.jpeg';
		},
		cleanProd(){
			// axios call to clean production
			axios.post('/api/clean-production')
			.then(response => {
				console.log(response.data);
				this.$toast.add({severity:'success', summary: 'Producción', detail: 'Producción limpiada', life: 3000});
			})
		}
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>


<style>
.topmenusel {
	font-weight: 700;
		margin: 15px;
		border: 1px solid rgb(255, 255, 255);
		padding: 12px;
    background-color: #000;
    color: white;
}

.switchbutton {
	font-weight: 700;
		margin: 15px;
		border: 1px solid white;
		padding: 12px;
	background-color: #0b3b5a;
	color: white;
}
</style>