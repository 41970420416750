<template>
     <div class="col-12 " >
<!-- 
      <div>
<pre>

  {{ order}}
</pre>
      </div> -->

               <Dialog header="REPORTAR INCIDENCIA" v-model:visible="showReportMotiveModal" :style="{width: '50vw'}">
            <div  class="grid" >
                <div v-for="report in reportOptions" class="col-12">
                    <Button  :label="report.name" class="reportButton w-full" @click="setReport(motive.id)" />
                </div>
            </div>
        </Dialog>

        <div class="flex justify-content-between flex-wrap">
          <div>
            <h3>O.T. {{ order.code }}</h3>
          </div>

          <div class="">
           
<a v-if="order.plane" 
                      :href="this.appBackend + '/uploads/' + order.plane.file"
                      target="_blank"
                    >
                      <Button class="ml-2 p-bp-button-sm p-button-danger"  icon="pi pi-file-pdf" label="VER PLANO"  />
                    </a>
            <Button class="ml-2"  icon="pi pi-camera" label="VER FOTOS " disabled />

            <Button class="ml-2 p-bp-button-sm p-button-warning" icon="pi pi-exclamation-triangle" label="REPORTAR INCIDENCIA" @click="showReportMotiveModal = true" />
          </div>
        </div>

        <div class="">
          <div class="formgrid grid">
            <div class="field col">
              <label for="detail">OPERACIÓN</label>
              <span id="detail" type="text" class="inputfield w-full" style="text-transform: uppercase;">
                {{ order.operation_name }}
              </span>
            </div>
            <div class="field col">
              <label for="detail">PIEZA</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ order.piecename }}
              </span>
            </div>

            <div class="field col">
              <label for="detail">CANTIDAD</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ order.cantidad }}
              </span>
            </div>
          </div>
        </div>

        <div class="">
          <div class="formgrid grid">
            <div class="field col">
              <label for="detail">MAQUINARÍA</label>
              <span id="detail" type="text" class="inputfield w-full"> 
                {{ order.machine_name }}
              </span>
            </div>
            <div class="field col">
              <label for="detail">MATERIAL</label>
              <span id="detail" type="text" class="inputfield w-full"> 

    

<div id="detail" type="text" class="inputfield w-full" v-for="mat in order.materials" :key="mat.id" style="margin-bottom: 5px; ">

  <div>

    {{ mat.material_name }} ({{ mat.quantity }} {{ mat.unit_name }}) 
  </div>
  <b>CÓDIGO:</b> {{ mat.material.material_code }}
  <br />
  <b>

    CERT:
  </b>
   {{ mat.material.cert_nro }} 
   <b>RTO:</b>
   {{ mat.material.rto_nro }}
   <b>UBICACIÓN:</b>
    {{ mat.material.location }}
    <hr style="border-width: 0.5px 0 0 0;border-top: solid #ada59d;"/>
</div>

              </span>
              

            </div>


            <div class="field col">
              <label for="detail">CLIENTE</label>
              <span id="detail" type="text" class="inputfield w-full">
                {{ order.client_name }}
              </span>
            </div>

          </div>
        </div>
        <!-- <div class="">
          <div class="formgrid grid">
            <div class="field col">
              <label for="detail">PLANOS</label>

              <div>
                <div v-for="(plan, index) in order.plans" :key="index">
                  <Button
                    :label="plan.name"
                    icon="pi pi-file-pdf"
                    iconPos="left"
                    class="btn plan_button"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> -->

         <div>
        <div class="mt-3">
          <div class="col-12">
            <OtiClock
              :order="order"
            />
          </div>
        </div>
        </div>
     </div>
</template>
<script>
import CrudService from "./../../services/crud.service.js";
import OtiActions from "../OtiActions.vue"; 
import OtiClock from "./../OtiClock.vue";
export default {
    props: ['order'],
    components: {
      OtiActions,
      OtiClock,
    },
    data() {
        return {
            showReportMotiveModal: false,
            materials: [],
            machines: [],
            tratamientos: [],
            clients: [],
            plans: [],
            
            reportOptions: [
                {name: 'Gerencia', id: 1},
                {name: 'Calidad', id: 3},
                {name: 'Ingeniería', id: 5},

            ],
        }
    },
    created() {
        if (!this.loggedIn) {
            this.$router.push('/');
        }
      //  this.getMaterials();
       // this.getMachines();
        //this.getTratamientos();
        //this.getClients();
        this.getPlans();
    },
    methods: {
        getMaterials() {
            CrudService.getCRUD('material', 'material').then(response => {
                this.materials = response;
            });
        },
        getMachines() {
            CrudService.getCRUD('maquinaria', 'maquinaria').then(response => {
                this.machines = response;
            });
        },
        getTratamientos() {
            // CrudService.getCRUD('tratamiento', 'tratamiento').then(response => {
            //     this.tratamientos = response;
            // });
        },
        getClients() {
            CrudService.getCRUD('cliente', 'cliente').then(response => {
                this.clients = response;
            });
        },
        getPlans() {
            // CrudService.getCRUD('planes', 'planes', this.order.id).then(response => {
            //     this.plans = response;
            // });
        },
        giveMeName(id, type) {
            let name = '';
            if (type === 'material') {
                this.materials.forEach(material => {
                    if (material.id === id) {
                        name = material.name;
                    }
                });
            } else if (type === 'machine') {
                this.machines.forEach(machine => {
                    if (machine.id === id) {
                        name = machine.name;
                    }
                });
            } else if (type === 'tratamiento') {
                this.tratamientos.forEach(tratamiento => {
                    if (tratamiento.id === id) {
                        name = tratamiento.name;
                    }
                });
            } else if (type === 'client') {
                this.clients.forEach(client => {
                    if (client.id === id) {
                        name = client.name;
                    }
                });
            }
            return name;
        }
    }
    
}
</script>


<style scoped>
.field > label {
  color: #9a9a9a;
  letter-spacing: 0.64px;
  font-size: 16px;
  text-transform: uppercase;
}

.field > span {
  display: block;
  text-align: left;
  letter-spacing: 0px;
  color: #0c0c0c;
  opacity: 1;
  border: none;
  background: transparent;
  font-size: 20px;
  line-height: 25px;
}

.plan_button {
  background: white;
  border: 1px solid #d8d8d8;
  font-size: 16px;
  line-height: 19px;
  color: #9a9a9a;
  width: 483px;
  height: 56px;
  text-align: left;
}

.plan_button:hover {
  border: 1px solid #d8d8d8;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  width: 483px;
  height: 56px;
}

.reportButton {
      font-size: 1.4rem;
    text-transform: uppercase;
    margin: 1%;
}
</style>