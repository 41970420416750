<template>
  <div class="grid">
<div class="col-12">
    <h4>LISTAS PARA DESPACHAR</h4>
</div>

    <div class="col-4" v-for="prod in orders" :key="prod.id" v-if="orders.length >= 1">
        <div class="card">

            <h5>  {{prod.client_name}} </h5>

            <div class="flex"  > 
                 <div class="os_number">
 
                 </div>
                 <div class="ml-auto">
                    
                 </div>
            </div>
            <hr />
            <div v-for="item in prod.items" class="flex justify-content-end">
                <div>

                    <strong><span style="color: blueviolet"> {{ item.piece_code }} </span> {{ item.name}}</strong>

                    <small>
<em> - {{ item.mp_to_use }}
</em>
                    </small>
                    <!-- <Badge :value="item.enstock" severity="success"  class="ml-1"
                    :v-if="Number(item.enstock).toFixed(0) - Number(item.despachado).toFixed(0) >= 0"
                    ></Badge>
                    - {{ (Number(item.enstock).toFixed(0))  - (Number(item.qty).toFixed(0))}} -->
                    
                </div>

                <div class="ml-auto">{{ item.despachado }}/{{ Number(item.qty).toFixed(0)}}</div>
               <div v-if="Number(item.percent_dispatched).toFixed(0) == 100">
                 <i class="pi pi-check " ></i>
               </div>
               <div v-else>
                 <i class="pi pi-hourglass " ></i>
               </div>
            </div>
            <hr />
            <div class="p-1 text-right">

                <Button label="Despachar disponibles" class="p-button-success" @click="dispatchOrder(prod.id)" icon="pi pi-truck" />
            </div>

        </div>
        
    </div>
    
    <div class="col-6 pt-0" v-else>
        <InlineMessage  severity="info"> 
            No hay ordenes para despachar </InlineMessage >

    </div>
    
  </div>

</template>

<script>
import otiService from '../../services/oti.service';

export default {
    
    data() {
        return {
            orders: [],
        }
    },
    mounted() {
        this.getOrders();
    },
    methods: {
        giveMePercent(dispatch, quantity) {
            return (dispatch / quantity) * 100;
        },
        dispatchOrder(id) {
            this.$router.push('/dispatch/' + id);
        },
        getOrders() {
            otiService.forDispatch(20).then(response => {
                console.log(response);
                this.orders = response.data
            });
        },
    }

}
</script>

<style>
.p-progressbar {
    border: 0 none;
    height: 1.5rem;
    background: #dee2e6;
    border-radius: 6px;
}
</style>