<template>
    <div class="grid">

        <Dialog header="Seleccione el operador" v-model:visible="showOperatorModal" :style="{width: '50vw'}">

            <Button 
                v-for="(operator, index) in operators"
                :key="operator.id"
                :label="operator.name" class="col-3 mx-1 my-1 p-button-outlined text-sm" 
                @click="selectedOperator = operator.id">
            </Button>

            <template #footer>
                <Button label="Cancelar" icon="pi pi-times" @click="closeBasic" class="p-button-text"/>
                <Button label="Confirmar" icon="pi pi-check" @click="setOperator()" autofocus />
            </template>
        </Dialog>

    <div  class="col-6" v-if="order.status == 0 && order.current_operator">
        <div :class="'oti_action st_bg_color_1'" @click="statusTo(1)">
            <div class="text-center">
                <div class="">
                    <i class="pi pi-play" ></i>
                    <span> INICIAR </span>
                </div>
                <div class="ml-auto p-2"  style=""><span> </span></div>
            </div>
        </div>
    </div>
    <div  class="col" v-if="order.status == 0 && !order.current_operator"  @click="statusTo(0)">
        <div :class="'oti_action st_bg_color_0'" >
            <div class="text-center">
                <div class="">
                    <i class="pi pi-user" ></i>
                    <span> ASIGNAR</span>
                </div>
            </div>
        </div>
    </div>
    <!-- <div  class="col" v-if="(order.status == 4) && userGroup == 1"  @click="statusTo(0)">
        <div :class="'oti_action st_bg_color_0'" >
            <div class="text-center">
                <div class="">
                    <div class=" ">
                    <i class="pi pi-user" ></i>
                    <span> REASIGNAR
                    </span>
                    </div>

                </div>
                
                <div class="ml-auto p-2"  style=""><span> </span></div>
            </div>
        </div>
    </div> -->
    <!-- <div  class="col" v-if="(order.status == 3) && userGroup == 1"  @click="statusTo(5)">
        <div :class="'oti_action st_bg_color_5'" >
            <div class="text-center">
                <div class="">
                    <div class=" ">
                    <i class="pi pi-star" ></i>
                    <span> A CALIDAD
                    </span>
                    </div>

                </div>
                
                <div class="ml-auto p-2"  style=""><span> </span></div>
            </div>
        </div>
    </div> -->
    <div  class="col" v-if="(order.status == 3) && (userGroup == 3 || userGroup == 1)"  @click="statusTo(4)">
        <div :class="'oti_action st_bg_color_3'" >
            <div class="text-center">
                <div class="">
                    <div class=" ">
                    <i class="pi pi-thumbs-up" ></i>
                    <span> APROBAR
                    </span>
                    </div>

                </div>
                
                <div class="ml-auto p-2"  style=""><span> </span></div>
            </div>
        </div>
    </div>
    <div  class="col" v-if="(order.status == 3) &&  (userGroup == 3 || userGroup == 1)"  @click="statusTo(5)">
        <div :class="'oti_action st_bg_color_7'" >
            <div class="text-center">
                <div class="">
                    <div class=" ">
                    <i class="pi pi-thumbs-down" ></i>
                    <span> DESAPROBAR
                    </span>
                    </div>

                </div>
                
                <div class="ml-auto p-2"  style=""><span> </span></div>
            </div>
        </div>
    </div>
    <div  class="col" v-if="order.status == 2 || order.status == 5" @click="statusTo(1)">
        <div :class="'oti_action st_bg_color_1'">
            <div class="text-center">
                <div class="">
                    <i class="pi pi-play" ></i>
                    <span> REANUDAR </span>
                </div>
                <div class="ml-auto p-2"  style=""><span> </span></div>
            </div>
        </div>
    </div>
    <div  class="col" v-if="order.status == 1" @click="statusTo(2)">
        <div :class="'oti_action st_bg_color_2'">
            <div class="text-center">
                <div class="">
                    <i class="pi pi-pause" ></i>
                    <span> PAUSAR </span>
                </div>
                <div class="ml-auto p-2"  style=""><span> </span></div>
            </div>
        </div>
    </div>
    <div  class="col" v-if="order.status == 1 || status == 2">
        <div :class="'oti_action st_bg_color_3'" @click="statusTo(3)">
            <div class="text-center">
                <div class="">
                    <i class="pi pi-check" ></i>
                    <span> FINALIZAR </span>
                </div>
                
            </div>
        </div>
    </div>


  </div>
</template>

<script>
import OtiService from "./../services/oti.service.js";
import OperatorService from "./../services/operator.service.js";

export default {

    props: {
        order: {
        },
        userGroup: {
        },
        operators: {
        },
    },
    emits: ['logChange'],
    data() {
        return {

            actualStatus: this.order.status,
            showOperatorModal: false,
            selectedOperator: null,
        }
    },
    created() {
        //this.getOperators();
    },
    methods: {
        getOperators() {
            OperatorService.getOperators().then(response => {
                
                this.operators = response;
            });
        },
        closeBasic() {
            this.showOperatorModal = false;
        },
        setOperator() {
            this.showOperatorModal = false;
            this.order.current_operator = this.selectedOperator;
           this.order.status = 4;

            let data = {
                order_id: this.order.id,
                current_operator: this.selectedOperator,
                status: this.order.status,
            };
            
            
            OtiService.updateOti(data).then(res => {
                console.log(res)
            }).catch(err => {
                console.log(err)
            })
        },
        statusTo(val){

            if(val == 0){
                this.showOperatorModal = true
                return
            }

            let data = {
                order_id: this.order.id,
                status: val,
            };

            this.order.status = val

            OtiService.updateOti(data).then(res => {
                console.log(res)
            }).catch(err => {
                console.log(err)
            })
            
            this.$emit('logChange', val)
        }, 
        init() {
            this.$emit('init')
        }, 
        finish() {
            this.$emit('finish')
        }
    },
    computed: {
        statusClass() {
            switch (this.actualStatus) {
                case 0:
                    return 'pi pi-exclamation-circle';
                case 1:
                    return 'pi pi-play';
                case 2:
                    return 'pi pi-pause';
                case 3:
                    return 'pi pi-star';
                default:
                    return 'pi pi-shopping-cart';
            }
        },
        statusName() {
      switch (value) {
        case 0:
          if(this.order.current_operator){
            return " Sin Iniciar por " + this.order.operator_name;
          }else{
            return " Sin asignar";
          }
        case 1:
          return " En proceso";
        case 2:
          return " En pausa";
        case 3:
          return " En calidad";
        case 4:
          return " Aprobada";
        case 5:
          return " Desaprobada";
        case 6:
          return " En TORNO / EN COLA ";
        case 7:
          return " EN TORNO / EN PROCESO";
          
        default:
          return value;
      }
        },
    },

}
</script>

<style lang="scss" scoped>

    .oti_action { 
        cursor: pointer;
        text-transform: uppercase;
        line-height: 19px;
        height: 35px;
        width: 120px;
        font-size: 16px;
        & >div i {
            font-size: 12px;
            line-height: 35px;
        }
        &.st_bg_color_7 {
            border: 1px solid #b71d1d71;
            background-color: #f808089a;
            & >div i {
                color: #b71d1d71;
            }
        }
        &.st_bg_color_3 {
            border: 1px solid #44b73a71;
            background-color: #44b73a71;
            & >div i {
                color: #44b73a;
            }
        }
        &.st_bg_color_2 {
            border: 1px solid #ff99005b;
            background-color: #ff99005b;
            & >div i {
                color: #FF9800;
            }
        }
        &.st_bg_color_1 {
            border: 1px solid #1c96c256;
            background-color: #1c96c256;
            & >div i {
                color: #0b3b5a;
            }
        }
        &.st_bg_color_0 {
            border: 1px solid rgba(28, 150, 194, 0.808);
            background-color: #2ce4d49a;
            & >div i {
                color: #000000;
            }
        }
        &.st_bg_color_4 {
            background-color: #d8d8d89a;
            & >div i {
                color: #807f7f;
            }
        }
        &.st_bg_color_5 {
            border: 1px solid rgb(36, 64, 108);
            background-color: rgb(79, 143, 247);
            color: #fff;
            & >div i {
                color: #fff;
            }
        }
    }



</style>>
