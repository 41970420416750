<template>
	<div>
  <div class="grid">

<h1>Jefe de Taller</h1>
  </div> 

    <h3> Ordenes de trabajo sin asignar </h3>
      <div class=""> 


        <div class="grid"> <OtiSquare v-for="order in orders_not_assigned" :key="order.id" :userGroup="opGroup" :order="order" /> </div>

 
        </div>
  
</div>

</template>

<script>
import CrudService from "./../../services/crud.service.js" 
import OtiSquare from "./OtiSquare.vue";
import ResumedCharts from "./ResumedCharts.vue";

import PlanillaInspeccion from "../PlanillaInspeccion.vue";

export default {
  props: ['opGroup'],
  data() {
    return {
      orders: [],
        orders_not_assigned: [],
    }
  },
  components: {
    OtiSquare,
    ResumedCharts,
    PlanillaInspeccion,
  },
	computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    currentGroup() {
      return this.$store.state.auth;
    },
  },
  
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }

    this.getOrders();
  }, 
  methods: {
    countStatus(status) {
      return this.orders.filter((order) => order.status === status).length;
    },
    getOrders() {

        CrudService.getCRUD("api/uncofing_orders").then((data) => (this.orders_not_assigned = data));


    },

  }

}
</script>

<style scoped>
</style>