import api from "./api";
import TokenService from "./token.service";

const API_URL = process.env.VUE_APP_ROOT_API;

class AuthService {
  login({ username, password }) {
    return api
      .post(API_URL + "/api/login", {
        username,
        password
      })
      .then((response) => {
        if (response.data.user) {
          console.log('recibiendo token');
          TokenService.setUser(response.data);
        }
        return response.data;
      });
  }


  loginOP({ id }) {
    return api
      .get("/api/token_operator/" + id + "")
      .then((response) => {
        if (response.data) {
          
          TokenService.setUser(response.data);
        }
        return response.data;
      });
  } 

  
  logout() {
    TokenService.removeUser();
  }
  register({ username, email, password }) {
    return api.post("/auth/signup", {
      username,
      email,
      password
    });
  }
}
export default new AuthService();