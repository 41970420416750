<template>
    <div  @click="openInspeccion">
        <div class="text-center">
                <div class="">
                    <i class="pi pi-star " style="font-size: 1.5rem; color: #"></i>
                    <span> CONTROL </span>
                </div>
            </div>
      <!-- <Button v-if="this.order"
    class="p-button-sm uppercase p-button-info ml-1"
    label="CONTROL"
    icon="pi pi-star"
   
    /> -->
  
    <!-- <div v-for="vc in this.order.variables">
  
      {{ vc.title }}
  
      ----
  
      {{ this.order.cantidad / vc.controls }} controles
  
    </div> -->
    
    <Dialog
  header=""
  v-model:visible="inspeccionVisible"
  :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
  :style="{ width: '80vw', marginLeft: '10vw' }"
  :modal="true"
  >
  <template #header>
    <div class="op_title">
    <h1>PLANILLA DE INSPECCIÓN EN PROCESOS</h1>

    
      <span>
  
        <h2 style="text-transform: uppercase;">
  
          {{ this.order.operation_name}} 
        </h2>
        </span> 
    </div>
  </template>
  
  <div class="w-100">
  
  </div> 
  
  <div v-for="(variab, pindex) in order.variables" :key="pindex">
    <hr/>

    <h2>
    {{ variab.title }} (VAR_ID: {{ variab.id}})
  </h2>
<p>

  <small>
    
    Se controla cada {{ variab.controls}} piezas
  </small>
  <p> Ultimo control realizado:  {{ lastControlMaded(variab.id)}}</p>
  <p> Control actual a informar: {{ actualControl(variab.id)}}</p>
</p> 
<div v-if="this.loading">

  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Espere...</span>
  </div>

</div>

 
<!-- /////////// DB_ID: {{ variab.id}}
{{ isFinalized(variab.id) }}

{{ controls }}
{{ this.isFinalized(variab.id) }}
{{this.lastControl(variab.id) }}
<pre>

  {{ controls }}
</pre>
-->

  
    
    <table class="table">
      <thead>
        <tr>
          <th rowspan="1" style="width: 10%">

            INSTRUMENTO
        
        </th>
          <th colspan="2" style="width: 20%"> <span style="font-weight: bold; color:indigo"> {{ variab.instrument.name }}</span></th>
          <th style="width: 20%"> <span style="font-weight: bold; color: black"> FRECUENCIA </span></th>
        </tr> 
          <tr><th rowspan="1" style="width: 10%">Variable a <br/> inspeccionar</th>
          <th style="max-width: 2vw; ">TIPO</th>
          <th style="max-width: 2vw; ">NUM</th>
            
          <th style="max-width: 2vw; "> Cada 
            <span style="color:darkred">

            {{ variab.controls }} 
          </span>
          piezas</th>
            <!-- <th v-for="indexTitle in Number(variab.controls)" :key="indexTitle" style="width: 5%">
            </th> 
            <th v-for="indexTitle2 in 8 -Number(variab.controls)" :key="indexTitle2" style="width: 5%">
                -
            </th>  -->
        </tr> 
      </thead>
      <tbody>
        <tr>
          <td rowspan="2" >{{ variab.inspect }}</td>
          <td rowspan="2" style="max-width: 2vw; "> {{ variab.instrument.tipo }} </td>
          <td rowspan="2" style="max-width: 2vw; ">  {{ variab.instrument.numero }}</td> 
          <td rowspan="2" style="max-width: 2vw; ">  
            <div v-if=" lastControlMaded(variab.id)">
            Ultimo control realizado {{ lastControlMaded(variab.id)}}
          </div> 
          </td> 
        </tr>
      </tbody>  
  
    </table>
  
  
  
<div v-if="!isFinalized(variab.id)" style="margin-top: 10px;">
  

  <div class="grid"  >

    <Dialog header="Alguna observación?" v-model:visible="displayConfirmation" :style="{width: '450px'}" :modal="true">
            <div class="confirmation-content">

                <div>
                  <Textarea v-model="observacion" placeholder="Observación" class="w-full"/>
                </div>
            </div>
            <template #footer>
                <Button label="Cancelar" icon="pi pi-times" @click="closeConfirmation" class="p-button-text"/>
                <Button label="Informar" icon="pi pi-check" @click="setControl(index, pindex, variab.id, false)" class="p-button-text p-button-success" autofocus />
            </template>
        </Dialog>

    <div  style="width: 50%">

      <Button :disabled="this.loading" @click="this.displayConfirmation = true" class="p-button-danger " icon="pi pi-times" style="height: 50px; width: 100%;margin: 0px 5px 0px 5px"></Button>
    </div>
    <div style="width: 50%">
      <Button :disabled="this.loading" @click="setControl(index, pindex, variab.id, true)" class="p-button-success " icon="pi pi-check" style="height: 50px; width: 100%;margin: 0px 5px 0px 5px"></Button>
    </div>  
  </div>
</div>  



<div v-else>
  <h3>
    Control finalizado.
  </h3>
</div>
  
  </div>  
  
  </Dialog>
  
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import CrudService from "../services/crud.service";
  import OtiService from "../services/oti.service";
  
  
  export default {
      props: ['order', 'opened', 'userGroup'],
      emits: ["allControls"],
    data() {
      return {
        displayConfirmation: false,
        observacion: "",
        loading: false,
        user: {},
        inspeccionVisible: false,
        pieceData: [], 
        controls: [
  
        ],
        varPage: {
          153: 0,
          154: 0,
          169: 2,
        },
        last_control: 
        
        {
            [153]: 1,
            [154]: 1,
            [169]: 2,
            }, 
        next_control:  {
            [153]: 22,
            [154]: 55,
            [169]: 12,
        }
        
      };
    },
    mounted: function () {
      this.getPieceData();
  
      this.user = JSON.parse(localStorage.getItem("api_token")).user;
      
    },
    methods: {
      nextControl(var_id) {


            // find var_id in order.variables
            let variable = this.order.variables.find((v) => v.id == var_id);
          console.log(this.order.cantidad)
          console.log( variable.controls)
            if(variable.controls <= this.order.cantidad) {
              return this.order.cantidad
            }

            return variable.controls


            // find controls maded to this variable
            let controls = this.order.controls.filter((c) => c.variable_id == var_id);

            // if there are no controls, return variable.controls
            if(controls.length == 0){
                return variable.controls;
            }
            
            // if there are controls, return the last control
            let last_c = controls[controls.length - 1].control_index 
            let every = variable.controls;
            // parse to int
            last_c = parseInt(last_c);
            every = parseInt(every);
            return last_c + every;
        
      
      },
        isFinalized(id){
          
          if(this.lastControlMaded(id) == this.order.cantidad){
            return true;
          }

          return this.lastControlMaded(id) == this.actualControl(id) ? true : false;

        },
        lastControl(var_id){

// find var_id in order.variables
let variable = this.order.variables.find((v) => v.id == var_id);

if(this.order.cantidad <= variable.controls){
  return this.order.cantidad
}
// find controls maded to this variable
let controls = this.order.controls.filter((c) => c.variable_id == var_id);

// if there are no controls, return variable.controls
if(controls.length == 0){
    return variable.controls;
}

// if there are controls, return the last control
let last_c = controls[controls.length - 1].control_index 
let every = variable.controls;
// parse to int
last_c = parseInt(last_c);
every = parseInt(every);
return last_c + every;
},
actualControl(var_id){
  // find in this.controls variable_id and value null
  let control = this.controls.find((c) => c.variable_id == var_id && c.value == null);

  return control ? control.control_index : null;




  let medida = this.order.variables.find((v) => v.id == var_id);
  if(!this.lastControlMaded(var_id)){
    return medida.controls;
  }else{
    let cind = parseFloat(medida.controls) + this.lastControlMaded(var_id);
    if(cind > this.order.cantidad){
      cind =  this.order.cantidad;
    }
    return cind
  }

},
        lastControlMaded(var_id){

// find controls maded to this variable
let controls = this.controls.filter((c) =>
{
  return c.variable_id == var_id && c.value
});


// if there are no controls, return variable.controls
if(controls.length == 0){
    return false;
}

// if there are controls, return the last control
let last_c = controls[controls.length - 1].control_index
// parse to int
last_c = parseInt(last_c);
return last_c;
 
},
      controlByRange: function (range) {
        
        // splice(0, 12)
        let arr = [];
        for (let i = 0; i < range; i++) {
          arr.push(i);
        } 
        return arr.splice(1, 12);
      },
      setControl(index, pindex, varid, status) {

        this.loading = true;
 
  
        let data = {
          status: status,
          operator: this.user.name,
          oti_operations_id: this.order.id,
          variable_id: varid,
          control_index: this.actualControl(varid),
          oti_id: this.order.oti_id,
          observacion: this.observacion,
  
        };

        
        OtiService.setControlStatus(data);


        this.displayConfirmation = false;

        this.order.controls.push(data);


        this.$emit("update", this.canLiberate());
  
        this.getPieceData();
   
      },
      canLiberate(){

       // check controls length
        if(this.controls.length == 0){
          return this.order.cantidad;
        }

        // get last control of each variable
        let lastControls = this.order.variables.map((v) => this.lastControl(v.id));
        // get controls maded of each variable
        let controlsMaded = this.order.variables.map((v) => this.lastControlMaded(v.id));
 
        // get min of controlsMaded
        let min = Math.min(...controlsMaded);

        return min
        

        //return last_controls
      },

      controlBoxClass(status) {
          
        // check user group 
  
        if (status == true) {
          return "controlBox_ok";
        } else if (status == false) {
          return "controlBox_bad";
        } else {
  
  
          return "controlBox_null";
        }
      },
      getPieceData() {
        this.loading = true
        CrudService.getCRUD('api/pieza_controls/' + this.order.id).then(
           (data) => 
           //(
        {         
            this.pieceData = data
            this.controls = data.controls

            this.$emit("update", this.canLiberate());

            this.loading = false

          }
            //)
        ); 
  
  
      },
      openInspeccion() {
   
  
        this.inspeccionVisible = true;
      },
      getOtiControlStatus(varid, index, pindex) {
   
   
  
        let control = this.pieceData.controls.find(
          (control) =>
            control.variable_id == varid && control.control_index == index
        );
  
        if (control) {
          //console.log(control.value)
          return control.value;
        } else {
          return null;
        } 
  
      },
      getOtiControlOperator(varid, index, pindex) {
  
  
        let control = this.pieceData.controls.find(
          (control) =>
            control.variable_id == varid && control.control_index == index
        );
  
        if (control) {
          return control.operator_name;
        } else {
          return ' - ';
        } 
      },
    },
  };
  </script> 
  <style  scoped>
  
  .pieza_name {
    color: #1c95c2;
  }
  .op_title {
    display: block;
    color: #9a9a9a;
    /*font-family: "Geogrotesque";*/
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  
  h1 {
    /*font-family: "Geogrotesque";*/
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    /* identical to box height */
  
    color: #0c0c0c;
  }
  
  h2 {
    /*font-family: "Geogrotesque";*/
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
  }
  .table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ececec;
  }
  
  .table thead {
    background-color: #e1e5e9;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #656565;
    border: 1px solid #d8d8d8;
  }
  
  .table_header {
        background-color: #e1e5e9;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #656565;
    border: 1px solid #d8d8d8;
  }
  
  .table td,
  th {
      padding: 10px;
    border: 1px solid #d8d8d8;
  }
  
  td {
      font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  
    text-align: center;
    text-transform: uppercase;
  
  color: #0C0C0C;
  
  }
  
  .ok_status {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  
  
  color: #66BB6A;
  }
  
  .bad_status {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  
  
  color: #EE6F60;
  }
   
  .controlBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
    
  }
  .p-checkbox-box .p-highlight {
    background-color: #66BB6A;
  }
  
  .controlBox_ok {
    background-color: #66BB6A;
    border-radius: 5px;
    padding: 5px;
    color: #fff;
  }
  
  .controlBox_bad {
    background-color: #EE6F60;
    border-radius: 5px;
    padding: 5px;
    color: #fff;
  }
  
  
  .controlBox_null {
    background-color: #fff;
    border-radius: 5px;
    padding: 5px;
    color: #000;
  }
  
  :deep .p-checkbox .p-checkbox-box.p-highlight {
      border-color: transparent;
    background-color: transparent;
  }
  :deep .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
      border-color: transparent;
    background-color: transparent;
      color: #ffffff;
  }
  
  .table td {
    padding: 5px 0px 5px 0px;
  }
  </style>
  
  
  <style lang="scss" scoped>
    .oti_action {
      cursor: pointer;
      text-transform: uppercase;
      margin-right: 20px;
      line-height: 19px;
      height: 35px;
      width: 120px;
      font-size: 16px;
      & > div i {
        font-size: 12px;
        line-height: 35px;
      }
      &.st_bg_color_7 {
        border: 1px solid #b71d1d71;
        background-color: #f808089a;
        & > div i {
          color: #b71d1d71;
        }
      }
      &.st_bg_color_3 {
        border: 1px solid #44b73a71;
        background-color: #44b73a71;
        & > div i {
          color: #44b73a;
        }
      }
      &.st_bg_color_2 {
        border: 1px solid #ff99005b;
        background-color: #ff99005b;
        & > div i {
          color: #ff9800;
        }
      }
      &.st_bg_color_1 {
        border: 1px solid #1c96c256;
        background-color: #1c96c256;
        & > div i {
          color: #0b3b5a;
        }
      }
      &.st_bg_color_0 {
        border: 1px solid rgba(28, 150, 194, 0.808);
        background-color: #2ce4d49a;
        & > div i {
          color: #000000;
        }
      }
      &.st_bg_color_4 {
        background-color: #d8d8d89a;
        & > div i {
          color: #807f7f;
        }
      }
      &.st_bg_color_5 {
        border: 1px solid rgb(36, 64, 108);
        background-color: rgb(79, 143, 247);
        color: #fff;
        & > div i {
          color: #fff;
        }
      }
    }
    </style>