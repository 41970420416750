<template>

    <div>

        <div class="grid">

            <div class="col-12">

                <div class="card">

                    <h5>Panel de 2A / Maqfer S.R.L. </h5>

                    

                </div>

            </div>

        </div>

    </div>
</template>