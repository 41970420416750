import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_ROOT_API;

class OtiService {
        ocToProduction(oc_id) {
            return axios.post(API_URL + '/api/produccion/oc-to-production', {
                oc_id: oc_id
            }, { headers: authHeader() }).then(response => {
        
                if(response.status == 200){
                    return response.data;
                } 

            }).catch(error => {
                
                return error.response;

        })
        
        }

        pieceToProduction(oc_item_id, oc_item_nro) {
            return axios.post(API_URL + '/api/produccion/oc-item-to-production', {
                oc_item_id: oc_item_id,
                oc_item_nro: oc_item_nro
            }, { headers: authHeader() }).then(response => {
        
                if(response.status == 200){
                    return response.data;
                } 

            }).catch(error => {
                
                return error.response;

        })
        
        }


        setParams(params) {
            return axios.post(API_URL + '/api/produccion/set-params', {
                params: params
            }, { headers: authHeader() }).then(response => {

                if(response.status == 200){
                    return response.data;
                }

            }).catch(error => {

                return error.response;

            })

        }
        updateOtiOperation(data) {

            
            return axios.put(
                    API_URL + '/api/orden/' + data.operation_id + '',
                    data,
                    { headers: authHeader() })
                    .then(response => {
    
                        if(response.status == 200){
                            return response.data;
                        } 
    
                    }).catch(error => {
                        
                        return error.response;
    
                })
    }
    withStatus(status) {

        console.log(status)
        return axios.get(API_URL + '/api/oti_in_status/' + status, { headers: authHeader() }).then(response => {

            //if(response.status == 200){
                return response.data;
            //}

        }).catch(error => {

            return error.response;

        })


    }
    
    forDispatch(status) {

        return axios.get(API_URL + '/api/oc_in_status/' + status, { headers: authHeader() }).then(response => {

            //if(response.status == 200){
                return response.data;
            //}

        }).catch(error => {

            return error.response;

        })


    }

    toSend(data) {

            
        return axios.post(
                API_URL + '/api/oti_to_send/' + data.oti_id + '',
                data,
                { headers: authHeader() })
                .then(response => {

                    if(response.status == 200){
                        return response.data;
                    } 

                }).catch(error => {
                    
                    return error.response;

            })
}
    setControlStatus(data) {

        return axios.post(API_URL + '/api/operation/control', 
            data
           , { headers: authHeader() }).then(response => {
   
               if(response.status == 200){
                   return response.data;
               }
   
           }).catch(error => {
   
               return error.response;
   
           })
    }

    assignOperation(data) {

        return axios.post(API_URL + '/api/orden/assign-operation', {
         data
        }, { headers: authHeader() }).then(response => {

            if(response.status == 200){
                return response.data;
            }

        }).catch(error => {

            return error.response;

        })

    }
        updateOti(data) {

                console.log(data)
                
                return axios.put(
                        API_URL + '/api/orden/' + data.order_id + '',
                        data,
                        { headers: authHeader() })
                        .then(response => {
        
                            if(response.status == 200){
                                return response.data;
                            } 
        
                        }).catch(error => {
                            
                            return error.response;
        
                    })
        }


        
}


export default new OtiService();