    <template>
  <div>

    <h1> TABLERO DEL OPERADOR </h1>
<!-- 
    <div  style="widht: 100%; background: gray; height: 5vh;">

    </div> -->

    <h5>Operaciones</h5>


    <div v-for="order in this.orders" :key="order.id"  :class="'card ' + bgClass(order.status)">

      <OtiForOp  :key="order.id" :order="order" /> 


      <hr />

    </div>

    <Message v-if="this.orders.length == 0" severity="info">
      No tienes ninguna operación asignada en este momento. 
    </Message>  

  </div>
</template>

<script>
import OtiClock from "./../OtiClock.vue";
import CrudService from "./../../services/crud.service.js" 
import OtiForOp from "./OtiForOp.vue";
export default {
  props: ['opId', 'opGroup'],
  components: {
    OtiClock,
    OtiForOp
  },
  data() {
    return {
      orders: [],
    };
  },
  created() {
    this.getOrders();
  },
  watch: {
    'orders.status': function(newStatus, oldStatus) {
      console.log('changed')
    }
  },
  methods: {
    bgClass(s){

      switch(s){
        case 0:
          return 'bg-gray'
        case 1:
          return 'bg-green'
        case 2:
          return 'bg-blue'
        case 3:
          return 'bg-yellow'
        case 4:
          return 'bg-gray'
        case 5:
          return 'bg-blue'
        case 6:
          return 'bg-purple'
        case 7:
          return 'bg-orange'
        case 8:
          return 'bg-gray'
        case 9:
          return 'bg-gray'
        case 10:
          return 'bg-gray'
        default:
          return 'bg-gray'
      }
    },  
    getOrders() {
      
      CrudService.getCRUD("api/otibyuser/" +  this.opId).then((data) => (this.orders = data));


    },
  },
};
</script>

 <style scoped>

  .p-fieldset-legend{
    text-transform: uppercase;
  }

  .bg-green {
    background: linear-gradient(158.84deg, rgba(144, 205, 147, 0.28) 11.11%, rgba(255, 255, 255, 0) 86.05%);
  }
  .bg-yellow {
    background: linear-gradient(158.84deg, rgba(253, 216, 125, 0.28) 11.11%, rgba(255, 255, 255, 0) 86.05%);
  }
  .bg-gray {
    background: linear-gradient(158.84deg, rgba(156, 174, 183, 0.28) 11.11%, rgba(255, 255, 255, 0) 86.05%);
}

.p-message.p-message-info {
    background: #e9e9ff;
    border: solid #696cff;
    border-width: 0 0 0 6px;
    color: #696cff;
    margin: 1rem 0;
    border-radius: 6px;
    font-size: 2rem;
    padding: 5px;
}
:deep
.p-message.p-message-info .p-message-icon {
  color: #696cff;
  font-size: 2rem
}

  </style>