<template>
  <div class="main_app_div">

    <div :class="containerClass" @click="onWrapperClick">

      <AppTopBar @menu-toggle="onMenuToggle" :user="userData" v-if="userData"
      @menu-2a="switchMenu2a" @menu-ferr="switchMenuFerr"  :isferraro="isferraro" />
      <div
        class="layout-sidebar"
        @click="onSidebarClick"
        v-if="userData && userData.roles && userData.roles[0].id == 1"
      >
        <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
      </div>
      <div
        class="layout-sidebar"
        @click="onSidebarClick"
        v-if="userData && userData.roles && userData.roles[0].id == 3"
      >
        <AppMenu :model="menuCalidad" @menuitem-click="onMenuItemClick" />
      </div>
      <div
        class="layout-sidebar"
        @click="onSidebarClick"
        v-if="userData && userData.roles && userData.roles[0].id == 6"
      >
        <AppMenu :model="menuFinanzas" @menuitem-click="onMenuItemClick" />
      </div>
      <div
        class="layout-sidebar"
        @click="onSidebarClick"
        v-if="userData && userData.roles && userData.roles[0].id == 7"
      >
        <AppMenu :model="menu2A" @menuitem-click="onMenuItemClick" />
      </div>
      <div :class="'layout-main-container ' + isOperator" >
        <div class="layout-main">
          <router-view />
        </div>
        <AppFooter />
      </div>
<!-- 
      <AppConfig
        :layoutMode="layoutMode"
        @layout-change="onLayoutChange"
        @change-theme="changeTheme"
      /> -->
      <transition name="layout-mask">
        <div
          class="layout-mask p-component-overlay"
          v-if="mobileMenuActive"
        ></div>
      </transition>
    </div>

  </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue";
import AppMenu from "./AppMenu.vue";
import AppConfig from "./AppConfig.vue";
import AppFooter from "./AppFooter.vue";
import Login from "./pages/Login.vue";
import UserService from "./services/user.service";

export default {
  emits: ["change-theme"],

  mounted() {
    if (!this.currentUser && !this.testModeOn) {
      this.$router.push("/operarios");
    }

    UserService.getUserBoard().then(
      (response) => {
        this.userData = response.data;
        //console.log(this.userData);


        if (!this.userData.roles.length) {
          alert(
            "el usuario no tiene grupo asignado..., porfavor comuniquese con el admin"
          );
          //this.$router.push('/login');
        }
        //console.log(this.userData.roles);
        if (
          this.userData &&
          this.userData.roles &&
          (this.userData.roles[0].id == 2
          || this.userData.roles[0].id == 4)
        ) {
          this.isOperator = "ml-0"
          //this.onMenuToggle();

        }
      },
      (error) => {
        // alert('ERROR')
        localStorage.removeItem("user");
        //this.$router.push('/login');

        this.userData =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.$router.push("/login");
      }
    );
  },
  data() {
    return {
      testModeOn: true,
      isLogged: false,
      layoutMode: "static",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      isferraro: true,
      userData: {},
      isOperator: "",
      menuFinanzas: [
      { items: [
            { label: "Inicio", icon: "pi pi-fw pi-home", to: "/" },
            { label: "Clientes", icon: "pi pi-fw pi-list", to: "/cliente" },
            {
              label: "Cotizaciones",
              icon: "pi pi-fw pi-dollar",
              to: "/cotizaciones",
            },
            { label: "Ventas", icon: "pi pi-fw pi-dollar", to: "/ventas" },
            {
              label: "Proveedores",
              icon: "pi pi-fw pi-list",
              to: "/proveedor",
            },
            { label: "C/C", icon: "pi pi-fw pi-list", to: "/cc" },
            { label: "Compras", icon: "pi pi-fw pi-list", to: "/compras" },
            { label: "Remitos recibidos", icon: "pi pi-truck", to: "/remitos" },
            { label: "REMITOS EMITIDOS", icon: "pi pi-fw pi-truck", to: "/entregas", active: ['dispatch', 'entregas'] },
            { label: "Contabilidad", icon: "pi pi-money-bill", to: "/contabilidad" },
            { label: "Cheques", svg: "cheque-icon.svg", to: "/cheques" },
            { label: "Bancos", svg: "bank_icon.png", to: "/bancos" },
      ]},
      ],
      menuCalidad: [
        {
          items: [
            { label: "Inicio", icon: "pi pi-fw pi-home", to: "/" },
            {
              label: "Produccion",
              icon: "pi pi-fw pi-tablet",
              to: "/produccion",
            },
            {
              label: "Maquinaria",
              icon: "pi pi-fw pi-share-alt",
              to: "/maquinaria",
            },
            { label: "Materiales", icon: "pi pi-fw pi-table", to: "/material" },
            {
              label: "Instrumentos",
              icon: "pi pi-fw pi-table",
              to: "/instrumentos",
            },
            { label: "Planos", icon: "pi pi-fw pi-file-pdf", to: "/planos" },
            { label: "Piezas", icon: "pi pi-fw pi-table", to: "/piezas" },
            {
              label: "Mantenimiento",
              icon: "pi pi-fw pi-calendar",
              to: "/mantenimiento",
            },
          ],
        },
      ],
      menu: [
        {
          //label: 'Panel',
          // items: [{
          //     label: 'Inicio', icon: 'pi pi-fw pi-home', to: '/'
          // }]
        },
        {
          //label: 'Terceros', icon: 'pi pi-fw pi-sitemap',
          items: [
            { label: "Inicio", icon: "pi pi-fw pi-home", to: "/" },
            { label: "Clientes", icon: "pi pi-fw pi-list", to: "/cliente" },
            {
              label: "Cotizaciones",
              icon: "pi pi-fw pi-dollar",
              to: "/cotizaciones",
            },
            { label: "Ventas", icon: "pi pi-fw pi-dollar", to: "/ventas" },
            {
              label: "Proveedores",
              icon: "pi pi-fw pi-list",
              to: "/proveedor",
            },
            { label: "Compras", icon: "pi pi-fw pi-list", to: "/compras" },
            { label: "Remitos recibidos", icon: "pi pi-truck", to: "/remitos" },
            { label: "Materiales", 
                  icon: "pi pi-fw pi-box", to: "/material" },
            //{label: 'Insumos', icon: 'pi pi-fw pi-table', to: '/insumos'},
            {
              label: "Produccion",
              icon: "pi pi-fw pi-tablet",
              to: "/produccion",
            },
            { label: "REMITOS EMITIDOS", icon: "pi pi-fw pi-truck", to: "/entregas", active: ['dispatch', 'entregas'] },
            { label: "Certificados", icon: "pi pi-fw pi-star", to: "/certificados" },
            { label: "Facturación", icon: "pi pi-fw pi-dollar", to: "/facturacion" },
            { label: "Contabilidad", icon: "pi pi-money-bill", to: "/contabilidad" },

            //{label: 'Maquinaria', icon: 'pi pi-fw pi-share-alt', to: '/maquinaria'},
            //{label: 'Mantenimiento', icon: 'pi pi-fw pi-calendar', to: '/mantenimiento'},
            //{label: 'Logística ', icon: 'pi pi-fw pi-directions', to: '/logistica'},
            {
              label: "Instrumentos",
              icon: "pi pi-fw pi-table",
              to: "/instrumentos",
            },
            {
              label: "Maquinaria",
              icon: "pi pi-fw pi-cog",
              to: "/maquinaria",
            },
            { label: "Planos", icon: "pi pi-fw pi-file-pdf", to: "/planos" },
            { label: "Piezas", icon: "pi pi-fw pi-box", to: "/piezas" },
            {
              label: "Estadísticas",
              icon: "pi pi-fw pi-chart-line",
              items: [
                {
                  label: "Clientes",
                  icon: "pi pi-fw pi-percentage",
                  to: "/estadisticas/clientes",
                },
                {
                  label: "Operarios",
                  icon: "pi pi-fw pi-users",
                  to: "/estadisticas/operarios",
                },
                {
                  label: "Materiales",
                  icon: "pi pi-fw pi-box",
                  to: "/estadisticas/materiales",
                },
                {
                  label: "Facturación",
                  icon: "pi pi-fw pi-money-bill",
                  to: "/estadisticas/facturacion",
                },
              ],
            },
            {
              label: "Configuraciones",
              icon: "pi pi-fw pi-cog",
              items: [
                { label: "Costos", icon: "pi pi-fw pi-dollar", to: "/costos" },
              ],
            },
            { label: "Usuarios", icon: "pi pi-fw pi-user", to: "/usuario" },
          ],
        },
      ],
      menu2A: [
        {
          label: '2A',
           items: [{
               label: 'Inicio', icon: 'pi pi-fw pi-home', to: '/2a/inicio'
           }]
        },
        {
          //label: 'Terceros', icon: 'pi pi-fw pi-sitemap',
          items: [        
            { label: "Ingeniería", icon: "pi pi-fw pi-list", to: "/2a/ingenieria" },
          ]
        },
        {
          //label: 'Terceros', icon: 'pi pi-fw pi-sitemap',
          items: [        
            { label: "Compras", icon: "pi pi-fw pi-list", to: "/2a/compras" },
          ]
        }
        ]
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      if (this.$route.path && this.$route.path.indexOf("/2a") !== -1 || this.$route.path.indexOf("/2A") !== -1) {
        
        this.switchMenu2a();

      }
      this.$toast.removeAllGroups();
    },
  },
  created() {},
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    switchMenuFerr(){
      this.isferraro = true;
      this.menu =  [
        {
          label: 'Ferraro',
           items: [{
               label: 'Inicio', icon: 'pi pi-fw pi-home', to: '/'
           }]
        },
        {
          //label: 'Terceros', icon: 'pi pi-fw pi-sitemap',
          items: [
            //{ label: "Inicio", icon: "pi pi-fw pi-home", to: "/" },
            { label: "Clientes", icon: "pi pi-fw pi-list", to: "/cliente" },
            {
              label: "Cotizaciones",
              icon: "pi pi-fw pi-dollar",
              to: "/cotizaciones",
            },
            { label: "Ventas", icon: "pi pi-fw pi-dollar", to: "/ventas" },
            {
              label: "Proveedores",
              icon: "pi pi-fw pi-list",
              to: "/proveedor",
            },
            { label: "Compras", icon: "pi pi-fw pi-list", to: "/compras" },
            { label: "Remitos recibidos", icon: "pi pi-truck", to: "/remitos" },
            { label: "Materiales", 
                  icon: "pi pi-fw pi-box", to: "/material" },
            //{label: 'Insumos', icon: 'pi pi-fw pi-table', to: '/insumos'},
            {
              label: "Produccion",
              icon: "pi pi-fw pi-tablet",
              to: "/produccion",
            },
            { label: "REMITOS EMITIDOS", icon: "pi pi-fw pi-truck", to: "/entregas", active: ['dispatch', 'entregas'] },
            { label: "Certificados", icon: "pi pi-fw pi-star", to: "/certificados" },
            { label: "Facturación", icon: "pi pi-fw pi-dollar", to: "/facturacion" },
            { label: "Contabilidad", icon: "pi pi-money-bill", to: "/contabilidad" },

            //{label: 'Maquinaria', icon: 'pi pi-fw pi-share-alt', to: '/maquinaria'},
            //{label: 'Mantenimiento', icon: 'pi pi-fw pi-calendar', to: '/mantenimiento'},
            //{label: 'Logística ', icon: 'pi pi-fw pi-directions', to: '/logistica'},
            {
              label: "Instrumentos",
              icon: "pi pi-fw pi-table",
              to: "/instrumentos",
            },
            {
              label: "Maquinaria",
              icon: "pi pi-fw pi-cog",
              to: "/maquinaria",
            },
            { label: "Planos", icon: "pi pi-fw pi-file-pdf", to: "/planos" },
            { label: "Piezas", icon: "pi pi-fw pi-box", to: "/piezas" },
            {
              label: "Estadísticas",
              icon: "pi pi-fw pi-chart-line",
              items: [
                {
                  label: "Clientes",
                  icon: "pi pi-fw pi-percentage",
                  to: "/estadisticas/clientes",
                },
                {
                  label: "Operarios",
                  icon: "pi pi-fw pi-users",
                  to: "/estadisticas/operarios",
                },
                {
                  label: "Materiales",
                  icon: "pi pi-fw pi-box",
                  to: "/estadisticas/materiales",
                },
                {
                  label: "Facturación",
                  icon: "pi pi-fw pi-money-bill",
                  to: "/estadisticas/facturacion",
                },
              ],
            },
            {
              label: "Configuraciones",
              icon: "pi pi-fw pi-cog",
              items: [
                { label: "Costos", icon: "pi pi-fw pi-dollar", to: "/costos" },
              ],
            },
            { label: "Usuarios", icon: "pi pi-fw pi-user", to: "/usuario" },
          ],
        },
      ]
    },  
    switchMenu2a() {
      

      this.isferraro = false;
 
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
      }

      return true;
    },
    changeTheme(event) {
      this.$emit("change-theme", event);
    },
  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },
    logo() {
      return this.$appState.darkTheme ? "images/logo.jpeg" : "images/logo.jpeg";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    AppConfig: AppConfig,
    AppFooter: AppFooter,
    Login: Login,
  },
};
</script>

<style lang="scss">
@import "./App.scss";

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #0b3b5a;
  border-color: #041f30;
  color: #fff;
}
</style>
