<template>
  <div>

    <Button v-if="this.order && this.order.variables"
  class="p-button-sm uppercase p-button-info ml-1"

  icon="pi pi-check-square"
  @click="openInspeccion"
  />


  <Dialog
header=""
v-model:visible="inspeccionVisible"
:breakpoints="{ '960px': '75vw', '640px': '90vw' }"
:style="{ width: '80vw', marginLeft: '10vw' }"
:modal="true"
>
<template #header>
  <div class="op_title">
  <h1>PLANILLA DE INSPECCIÓN EN PROCESOS</h1>
  
    <span>

      <h2 style="text-transform: uppercase;">

        {{ this.order.operation_name}} 
        <strong style="color: black;">
          TOTAL DE PIEZAS: {{ this.order.quantity }}
        </strong>
      </h2>
      </span> 
  </div>
</template>

<div class="w-100">

</div> 

<div v-for="(variab, pindex) in order.variables" :key="pindex">

  <h2>
    {{ variab.title }} (VAR_ID: {{ variab.id}})
  </h2>
<p>

  <small>
    
    Se controla cada {{ variab.controls}} piezas
  </small>
</p> 

  <DataTable :value="controlsByVar(variab.id)" :rowClass="rowClass" responsiveLayout="scroll">
            <Column field="updated_at" header="Fecha">
              <template #body="slotProps">
                <span v-if="slotProps.data.value">{{ slotProps.data.updated_at }}</span>
                <span v-else> - </span>

              </template>
            </Column>
            <Column field="value" header="Estado">
              <template #body="slotProps">
                <span v-if="slotProps.data.value == 1">
                  <i class="pi pi-check" style="color: green;"></i>

                </span>
                <span v-else-if="slotProps.data.value == 0">
                  <i class="pi pi-times" style="color: red;"></i>

                </span>
                <span v-else> - </span>

              </template>
            </Column>
            <Column field="observation" header="Observación">
            </Column>
            <Column field="operator_name" header="Operador">
              <template #body="slotProps">
                <span v-if="slotProps.data.value" style="text-transform: uppercase">
                  <i class="pi pi-user" style="color: blue;"></i> 
                  {{ slotProps.data.operator_name  }}</span>
                <span v-else> - </span>

              </template>
            </Column>
            <Column field="control_index" header="Frecuencia">
            </Column>
        </DataTable>



</div>  

</Dialog>

  </div>
</template>

<script>
import axios from 'axios';
import CrudService from "../services/crud.service";
import OtiService from "../services/oti.service";


export default {
    props: ['order', 'opened', 'userGroup'],
    emits: ["allControls"],
  data() {
    return {
      user: {},
      inspeccionVisible: false,
      pieceData: [], 
      controls: [

      ],
      varPage: {
        153: 0,
        154: 0,
        169: 2,
      }
    };
  },
  mounted: function () {
    this.getPieceData();

    this.user = JSON.parse(localStorage.getItem("api_token")).user;
    
  },
  methods: {
    rowClass(data) {
            if(!data.value) return;

            return data.value === '1' ? 'row-good': 'row-bad';
        },
    controlsByVar(id){

      let controls = this.controls.filter((control) => {
        return control.variable_id == id;
      });

      return controls;
    
    },  
    controlByRange: function (range) {
      
      // splice(0, 12)
      let arr = [];
      for (let i = 0; i < range; i++) {
        arr.push(i);
      } 
      return arr.splice(1, 12);
    },
    setControl(index, pindex, varid, status) {

      let data = {
        status: status,
        operator: this.user.name,
        oti_operations_id: this.order.id,
        variable_id: varid,
        control_index: index,
        oti_id: this.order.id

      };
      
      OtiService.setControlStatus(data);

      this.getPieceData();
 
    },
    controlBoxClass(status) {
        
      // check user group 

      if (status == true) {
        return "controlBox_ok";
      } else if (status == false) {
        return "controlBox_bad";
      } else {


        return "controlBox_null";
      }
    },
    getPieceData() {

      CrudService.getCRUD('api/pieza_controls/' + this.order.id).then(
         (data) => 
         //(
{          this.pieceData = data
          this.controls = data.controls
        }
          //)
      ); 


    },
    openInspeccion() {
 

      this.inspeccionVisible = true;
    },
    getOtiControlStatus(varid, index, pindex) {
 
 

      let control = this.pieceData.controls.find(
        (control) =>
          control.variable_id == varid && control.control_index == index
      );

      if (control) {
        //console.log(control.value)
        return control.value;
      } else {
        return null;
      } 

    },
    getOtiControlOperator(varid, index, pindex) {


      let control = this.pieceData.controls.find(
        (control) =>
          control.variable_id == varid && control.control_index == index
      );

      if (control) {
        return control.operator_name;
      } else {
        return ' - ';
      } 
    },
  },
};
</script> 
<style  scoped>

.pieza_name {
  color: #1c95c2;
}
.op_title {
  display: block;
  color: #9a9a9a;
  /*font-family: "Geogrotesque";*/
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

h1 {
  /*font-family: "Geogrotesque";*/
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  /* identical to box height */

  color: #0c0c0c;
}

h2 {
  /*font-family: "Geogrotesque";*/
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
}
.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ececec;
}

.table thead {
  background-color: #e1e5e9;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #656565;
  border: 1px solid #d8d8d8;
}

.table_header {
      background-color: #e1e5e9;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #656565;
  border: 1px solid #d8d8d8;
}

.table td,
th {
    padding: 10px;
  border: 1px solid #d8d8d8;
}

td {
    font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
/* identical to box height */

  text-align: center;
  text-transform: uppercase;

color: #0C0C0C;

}

.ok_status {
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
/* identical to box height */


color: #66BB6A;
}

.bad_status {
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
/* identical to box height */


color: #EE6F60;
}
 
.controlBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  
}
.p-checkbox-box .p-highlight {
  background-color: #66BB6A;
}

.controlBox_ok {
  background-color: #66BB6A;
  border-radius: 5px;
  padding: 5px;
  color: #fff;
}

.controlBox_bad {
  background-color: #EE6F60;
  border-radius: 5px;
  padding: 5px;
  color: #fff;
}


.controlBox_null {
  background-color: #fff;
  border-radius: 5px;
  padding: 5px;
  color: #000;
}

:deep .p-checkbox .p-checkbox-box.p-highlight {
    border-color: transparent;
  background-color: transparent;
}
:deep .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: transparent;
  background-color: transparent;
    color: #ffffff;
}

.table td {
  padding: 5px 0px 5px 0px;
}
</style>


<style lang="scss" scoped>
  .oti_action {
    cursor: pointer;
    text-transform: uppercase;
    margin-right: 20px;
    line-height: 19px;
    height: 35px;
    width: 120px;
    font-size: 16px;
    & > div i {
      font-size: 12px;
      line-height: 35px;
    }
    &.st_bg_color_7 {
      border: 1px solid #b71d1d71;
      background-color: #f808089a;
      & > div i {
        color: #b71d1d71;
      }
    }
    &.st_bg_color_3 {
      border: 1px solid #44b73a71;
      background-color: #44b73a71;
      & > div i {
        color: #44b73a;
      }
    }
    &.st_bg_color_2 {
      border: 1px solid #ff99005b;
      background-color: #ff99005b;
      & > div i {
        color: #ff9800;
      }
    }
    &.st_bg_color_1 {
      border: 1px solid #1c96c256;
      background-color: #1c96c256;
      & > div i {
        color: #0b3b5a;
      }
    }
    &.st_bg_color_0 {
      border: 1px solid rgba(28, 150, 194, 0.808);
      background-color: #2ce4d49a;
      & > div i {
        color: #000000;
      }
    }
    &.st_bg_color_4 {
      background-color: #d8d8d89a;
      & > div i {
        color: #807f7f;
      }
    }
    &.st_bg_color_5 {
      border: 1px solid rgb(36, 64, 108);
      background-color: rgb(79, 143, 247);
      color: #fff;
      & > div i {
        color: #fff;
      }
    }
  }
  </style>