<template>
	<div class="layout-footer">
		
		<span class="font-medium ml-2">{{ this.appTitle }} </span>
	</div>
</template>

<script>
	export default {
		name: "AppFooter",
		methods: {
			footerImage() {
				return 'images/logo.jpeg';
				//return this.$appState.darkTheme ? 'images/logo_dark.jpeg' : 'images/logo.jpeg';
			}
		},
		computed: {
			darkTheme() {
				return this.$appState.darkTheme;
			}
		}
	}
</script>