class TokenService {
    getLocalRefreshToken() {
      const user = JSON.parse(localStorage.getItem("api_token"));
      return user?.api_token;
    }
    getLocalAccessToken() {
      const user = JSON.parse(localStorage.getItem("api_token"));
      return user?.api_token;
    }
    updateLocalAccessToken(token) {
      console.log('updating token')
      let user = JSON.parse(localStorage.getItem("api_token"));
      user.api_token = token;
      localStorage.setItem("api_token", JSON.stringify(user));
    }
    getUser() {
      return JSON.parse(localStorage.getItem("api_token"));
    }
    setUser(user) {
      //console.log(JSON.stringify(user.user));
      //localStorage.setItem("user", JSON.stringify(user.user));
      localStorage.setItem("api_token", JSON.stringify(user));
    }
    removeUser() {
      localStorage.removeItem("user");
    }
  }
  export default new TokenService();