<template>
<div >
<div v-if="order.machine_id !== 1">
  <div v-if="size == 2 || size !== 0">
    <div class="row mb-2">
      <div :class="'oti_clock st_bg_color_' + order.status">
        <div class="flex">
          <div class="p-1">
            <i :class="statusClass"></i>
            <span> {{ statusName }}</span>
          </div>
          <div class="ml-auto p-2" style="font-size: 32px; line-height: 40px">
            <span>
              {{ secsParsed(order.time) }} 
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <OtiActions :order="order" @logChange="actionClicked" :opGroup="this.opGroup" :operators="this.operators" :mpToBuild="this.mpToBuild" />
    </div>
  </div>
  <div v-else-if="size == 1">

  </div>
  <div v-else-if="size == 0">

        {{ secsParsed(order.time) }}

  </div>


</div>

<div v-else>

  <div v-if="size == 2 || size !== 0">
    <div class="row mb-2">
      <div :class="'oti_clock st_bg_color_' + order.status">
        <div class="flex">
          <div class="p-1">
            <i :class="statusClass"></i>
            <span> {{ statusName }}</span>
          </div>
          <div class="ml-auto p-2" style="font-size: 32px; line-height: 40px">
            <span>
              {{ secsParsed(order.time) }} 
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      
    </div>
  </div>
  <div v-else-if="size == 1">

  </div>
  <div v-else-if="size == 0">

        {{ secsParsed(order.time) }}

  </div>

</div>


</div>
</template>

<script>
import OtiActions from "./OtiActions";
export default {
  components: {
    OtiActions,
  },
  props: ["order", "size", "opGroup", "operators", "mpToBuild"],
  data() {
    return {
      dinamycStatus: this.order.status,
      elapsed: 0,
    };
  },
  created() {
    setInterval(
      function () {
        if (this.order.status == 1) {
          //console.log(this.order.time);
          this.order.time++;
        } else {

        }
      }.bind(this),
      1000
    );
  },
  mounted() {
    this.dinamycStatus = this.order.status;
  },
  methods: {
    actionClicked(e) {
      this.dinamycStatus = e;

      console.log("actionClicked", e, this.elapsed);
    },
    secsParsed: function (sec) {
      var hours = Math.floor(sec / 3600);
      hours >= 1 ? (sec = sec - hours * 3600) : (hours = "00");
      var min = Math.floor(sec / 60);
      min >= 1 ? (sec = sec - min * 60) : (min = "00");
      sec < 1 ? (sec = "00") : void 0;

      let ph = "00";
      if (hours >= 1) {
        ph = String(hours).padStart(2, "0");
      }
      let sec2 = "00";
      if (sec >= 1) {
        sec2 = String(sec).padStart(2, "0");
      }

      min.toString().length == 1 ? (min = "0" + min) : void 0;

      return ph + ":" + min + ":" + sec2;
    },
  },
  computed: {
    tornoClass() {
      return "pi pi-cog";
    },
    tornoName() {
      return " Torno sin iniciar " + this.order.status;
    },
    statusClass() {
      switch (this.order.status) {
        case 0:
          return "pi pi-user";
        case 1:
          return "pi pi-play";
        case 3:
          return "pi pi-pause";
        case 2:
          return "pi pi-check";
        case 3:
          return "pi pi-search";
        default:
          return "pi pi-exclamation-circle";
      }
    },
    statusName() {
      switch (this.order.status) {
        case 0:
          if(this.order.current_operator){
            return " Sin Iniciar por " + this.order.operator_name;
          }else{
            return " Sin iniciar";
          }
        case 1:
          return " En proceso";
        case 2:
          return " Finalizada";
          case 3:
            return " En pausa";
        case 4:
          return " Aprobada";
        case 5:
          return " Desaprobada";
        case 6:
          return " En TORNO / EN COLA ";
        case 7:
          return " EN TORNO / EN PROCESO";
          
        default:
          return this.order.status;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.oti_clock {
  padding: 5px 25px 5px 25px;
  text-transform: uppercase;
  line-height: 50px;
  font-size: 26px;
  & > div i {
    font-size: 22px;
    line-height: 30px;
  }
  &.st_bg_color_1 {
    border: 1px solid #44b73a71;
    background-color: #44b73a71;
    & > div i {
      color: #44b73a;
    }
  }
  &.st_bg_color_0 {
    border: 1px solid #1c96c256;
    background-color: #1c96c256;
    & > div i {
      color: #0b3b5a;
    }
  }
  &.st_bg_color_3{
    border: 1px solid #ff99005b;
    background-color: #ff99005b;
    & > div i {
      color: #ff9800;
    }
  }
  &.st_bg_color_2 {
    border: 1px solid #1c96c256;
    background-color: #1c96c256;
    & > div i {
      color: #0b3b5a;
    }
  }
  &.st_bg_color_0 {
    background-color: #d8d8d89a;
    background-color: #d8d8d89a;
    & > div i {
      color: #807f7f;
    }
  }
}
</style> 
