<template>

    <div>


        <h3>
            Panel de Finanzas
        </h3>
        <div class="grid">

            <div class="col-12">
                <OrdersToDispatch />

            </div>

        </div>
    </div>

</template>

<script>
    import OrdersToDispatch from "./OrdersToDispatch.vue";
    
        export default {
    
            name: "FinanzasDashboard",
    
            components: {
    
                OrdersToDispatch
    
            },
    
            data() {
    
                return {
     
    
                };
    
            },
    
            created() {
     
    
            },
    
            methods: { 
 
    
            }
    
        };

</script>