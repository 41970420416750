<template>
	<div>
  <div class="grid">


  </div> 

  <TabView ref="tabview1">
    <TabPanel header="EN CALIDAD">
      <div class="grid"> 
        {{ orders }}
        <!-- <OtiSquare v-for="order in orders.filter((order) => order.status === 3)" :key="order.id" :userGroup="opGroup" :order="order" />  -->
        
        </div>
    </TabPanel>
  </TabView>

  <PlanillaInspeccion />
  
</div>

</template>

<script>
import CrudService from "./../../services/crud.service.js" 
import OtiSquare from "./OtiSquare.vue";
import ResumedCharts from "./ResumedCharts.vue";

import PlanillaInspeccion from "../PlanillaInspeccion.vue";

export default {
  props: ['opGroup'],
  data() {
    return {
      orders: [],
    }
  },
  components: {
    OtiSquare,
    ResumedCharts,
    PlanillaInspeccion,
  },
	computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    currentGroup() {
      return this.$store.state.auth;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }

    this.getOrders();
  }, 
  methods: {
    countStatus(status) {
      return this.orders.filter((order) => order.status === status).length;
    },
    getOrders() {

        CrudService.getCRUD("orden", "orden").then((data) => (this.orders = data));


    },

  }

}
</script>

<style scoped>
</style>