import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_ROOT_API;

class CrudService {

        getMaestro2A() {
                return axios.get(API_URL + '/api/maestro2a', { headers: authHeader() }).then(response => {
                        return response.data;
                }).catch(error => {
                        return error.response.data;
                }
                )
                
        }

        getCRUD(data1, data2 = data1, data3 = null) {

                return axios.get(
                    API_URL + '/' + data1,
                    { headers: authHeader() })
                    .then(response => {
                        return response.data;
                    }).catch(error => {
                        return error.response.data;
                })


        }

        createCRUD(path, extra_path = path, data) {

        return axios.post(
                API_URL + '/' + path + '/' + extra_path + '',
                data,
                { headers: authHeader(), "Content-Type": "multipart/form-data", })
                .then(response => {

                    if(response.status == 200){
                        return response.data;
                    } 

                }).catch(error => {
                    
                    return error.response;

            })
        }  
        updatePiece(data) {
 

            return axios.post(
                    API_URL + '/api/pieza/update',
                    data,
                    { headers: authHeader() })
                    .then(response => {
    
                        if(response.status == 200){
                            return response.data;
                        } 
    
                    }).catch(error => {
                        
                        return error.response;
    
                })
    }
    
        updatePlane(path, extra_path = path, data) {

            console.log(API_URL + '/' + path + '/' + extra_path );
 

            return axios.post(
                    API_URL + '/' + path + '/' + extra_path,
                    data,
                    { headers: authHeader(), "Content-Type": "multipart/form-data", })
                    .then(response => {
    
                        if(response.status == 200){
                            return response.data;
                        } 
    
                    }).catch(error => {
                        
                        return error.response;
    
                })
    }
    
    createPlane(path, extra_path = path, data) {

        console.log(API_URL + '/' + path + '/' + extra_path );


        return axios.post(
                API_URL + '/' + path + '/' + extra_path,
                data,
                { headers: authHeader(), "Content-Type": "multipart/form-data", })
                .then(response => {

                    if(response.status == 200){
                        return response.data;
                    } 

                }).catch(error => {
                    
                    return error.response;

            })
}
        updateCRUD(path, extra_path = path, data, optional = '') {

                console.log(API_URL + '/' + path + '/' + extra_path + '/' + optional + '');
                console.log(optional)


                let headers = authHeader();
 
                return axios.put(
                        API_URL + '/' + path + '/' + extra_path + '/' + data.id ,
                        data,
                        { headers: authHeader(), "Content-Type": "multipart/form-data", })
                        .then(response => {
        
                            if(response.status == 200){
                                return response.data;
                            } 
        
                        }).catch(error => {
                            
                            return error.response;
        
                    })
        }
        deleteCRUD(path, extra_path = path, id) {

                return axios.delete(
                        API_URL + '/' + path + '/' + extra_path + '/' + id + '',
                        { headers: authHeader() })
                        .then(response => {
        
                            if(response.status == 200){
                                return response.data;
                            } 
        
                        }).catch(error => {
                            
                            return error.response;
        
                    })
        }

        postCRUD(path, data) {

                return axios.put(
                        API_URL + '/' + path + '',
                        data,
                        { headers: authHeader() })
                        .then(response => {

                            if(response.status == 200){
                                return response.data;
                            } 

                        }).catch(error => {
                            
                            return error.response;

                    })
        }

        updatePieceOperations(id, data) {

            return axios.put(
                    API_URL + '/api/upo/' + id + '',
                    data,
                    { headers: authHeader() })
                    .then(response => {

                        if(response.status == 200){
                            return response.data;
                        } 

                    }).catch(error => {
                        
                        return error.response;

                })
    }

        
}


export default new CrudService();